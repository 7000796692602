import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import { checkAuth } from '../apis/auth'

export const useRedirectToLogin = (): void => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  /* ログインしているかチェック */
  useEffect(() => {
    ;(async () => {
      try {
        await checkAuth()
        dispatch({ type: 'setIsLoggedIn', isLoggedIn: true })
        if (location.pathname.indexOf('login') > -1) {
          navigate('/')
        }
      } catch (error) {
        navigate('/login')
      }
    })()
  }, [dispatch, navigate])
}
