import { AxiosPromise } from 'axios'

import { GroupWithInvoices, Group } from '../types/models/Invoice'

import client from './client'

export const getInvoiceDetail = (
  group_id: string,
): AxiosPromise<GroupWithInvoices> => {
  return client.get(`/groups/${group_id}`)
}

export const getInvoiceGroups = (): AxiosPromise<Group[]> => {
  return client.get(`/groups`)
}
