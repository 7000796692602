import { AxiosPromise } from 'axios'

import {
  PutInvoiceRequest,
  PostInvoiceItemRequest,
} from '../types/models/Invoice'

import client from './client'

export const editInvoice = (
  request_body: PutInvoiceRequest,
  group_id: string,
  invoice_id: string,
): AxiosPromise<string> => {
  return client.put(`groups/${group_id}/invoices/${invoice_id}`, request_body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const createInvoiceItem = (
  request_body: PostInvoiceItemRequest,
  group_id: string,
  invoice_id: string,
): AxiosPromise<{
  id: string
}> => {
  return client.post(
    `groups/${group_id}/invoices/${invoice_id}/invoice_items`,
    request_body,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}
