import { FC, useEffect, useState } from 'react'

import { Alert, Snackbar } from '@mui/material'
import { Outlet, useParams, useNavigate } from 'react-router-dom'

import { getInvoiceGroups } from '../../apis/getInvoiced'
import { GroupDrawerToggleButton } from '../../hooks/useDrawerToggle'
import { Group } from '../../types/models/Invoice'

const InvoiceGroupsPage: FC = () => {
  /**
   * Global
   */
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const navigate = useNavigate()

  const renderAlert = () => (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        open={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
      >
        <Alert elevation={6} severity="error" variant="filled">
          エラーが発生しました
        </Alert>
      </Snackbar>
    </div>
  )

  /**
   * 請求書group
   */
  const params = useParams<{ groupId: string }>()
  const [groupsSummary, setGroupsSummary] = useState<Group[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        const { data: groupsData } = await getInvoiceGroups()

        // groupIdがない場合は、最初のgroupIdに遷移する
        if (!params.groupId) {
          if (groupsData.length === 0) return
          navigate(`/invoices/${groupsData[0].id}`)
        }

        setGroupsSummary(groupsData)
      } catch (error) {
        setIsAlertOpen(true)
        return
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clickGroup = (group: Group) => {
    navigate(`/invoices/${group.id}`)
  }

  const renderInvoices = () => (
    <>
      <GroupDrawerToggleButton
        clickGroup={clickGroup}
        groups={groupsSummary}
        selectedGroupId={params.groupId}
      />
      <Outlet />
    </>
  )

  return (
    <div style={{ height: '100vh' }}>
      {renderInvoices()}
      {renderAlert()}
    </div>
  )
}
export default InvoiceGroupsPage
