import { AxiosPromise } from 'axios'

import { MasterUnitPrice, PostUnitPrice } from './../types/models/Invoice'
import client from './client'

const url = '/unit_prices/'

export const crateUnitPrice = (
  unit_price: PostUnitPrice,
): AxiosPromise<MasterUnitPrice> => {
  return client.post(url, unit_price, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const getUnitPrices = (): AxiosPromise<MasterUnitPrice[]> => {
  return client.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const patchUnitPrice = (
  unit_price: MasterUnitPrice,
): AxiosPromise<void> => {
  return client.patch(`${url}${unit_price.id}/`, unit_price, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const deleteUnitPrice = (id: string): AxiosPromise<void> => {
  return client.delete(`${url}${id}/`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
