import { FC, CSSProperties } from 'react'

import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

import { theme } from '../../styles/theme'

type Props = {
  hasHighCheckPriority?: boolean
  helperText?: string
  inputType?: 'number'
  isError?: boolean
  label?: string
  maxWidth?: CSSProperties['maxWidth']
  value: string | number
}
const StyledInputBox = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '32px',
    borderRadius: '0',
  },
  width: '100%',
})

export const InputBox: FC<Props> = ({
  hasHighCheckPriority,
  helperText,
  inputType,
  isError,
  label,
  maxWidth,
  value,
}) => {
  return (
    <StyledInputBox
      error={isError}
      helperText={helperText}
      label={label}
      style={{
        maxWidth,
        backgroundColor: hasHighCheckPriority
          ? theme.palette.warning.light
          : '#fff',
      }}
      type={inputType}
      value={value}
    />
  )
}
