import { FC } from 'react'

import {
  InvoiceDrawerPressList,
  GroupDrawerPressList,
} from '../components/templates/Drawer'
import { Invoice, Group } from '../types/models/Invoice'

type InvoiceProps = {
  clickInvoice: (invoice: Invoice) => void,
  invoices: Invoice[],
  selectedInvoice: Invoice | undefined
}

type GroupProps = {
  clickGroup: (group: Group) => void
  groups: Group[]
  selectedGroupId: string | undefined
}

export const InvoiceDrawerToggleButton: FC<InvoiceProps> = ({
  clickInvoice,
  invoices,
  selectedInvoice,
}) => {
  return (
    <InvoiceDrawerPressList
      clickInvoice={clickInvoice}
      invoices={invoices}
      selectedInvoice={selectedInvoice}
    />
  )
}

export const GroupDrawerToggleButton: FC<GroupProps> = ({
  clickGroup,
  groups,
  selectedGroupId,
}) => {
  return (
    <GroupDrawerPressList
      clickGroup={clickGroup}
      groups={groups}
      selectedGroupId={selectedGroupId}
    />
  )
}
