import { FC, Fragment, useContext, useEffect, useState } from 'react'

import { Alert, Box, Button, Snackbar, Stack } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridPreProcessEditCellProps,
  useGridApiRef,
} from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'

import {
  createArtist,
  getArtists,
  deleteArtist,
  patchArtist,
} from '../../apis/artist'
import { ErrorModalContext } from '../../App'
import CreateArtistButton from '../../components/templates/CreateArtistButton'
import DeleteButton from '../../components/templates/DeleteButton'
import { Header } from '../../components/templates/Header'
import { Artist } from '../../types/models/Invoice'

const ArtistPage: FC = () => {
  const { errorMessages, setIsError } = useContext(ErrorModalContext)
  const [artists, setArtists] = useState<Artist[]>([])
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [isUnsaved, setIsUnsaved] = useState(false)
  const navigate = useNavigate()
  const apiRef = useGridApiRef()

  const columns: GridColDef[] = [
    {
      field: 'artist_name',
      headerName: 'アーティスト名',
      editable: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: (params) => editCell(params),
    },
    {
      field: 'artist_id',
      headerName: '取引先ID',
      type: 'text',
      editable: true,
      flex: 0.3,
      headerAlign: 'center',
      preProcessEditCellProps: (params) => editCell(params),
    },
    {
      field: 'invoice_id',
      headerName: '請求書ID(半角数字)',
      type: 'text',
      editable: true,
      flex: 0.3,
      headerAlign: 'center',
      preProcessEditCellProps: (params) => editInvoiceIdCell(params),
    },
    {
      field: 'shop_name',
      headerName: 'ショップ名',
      editable: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: (params) => editCell(params),
    },
    {
      field: 'edit_button',
      headerName: '更新',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => Update(params.row as Artist)}
        >
          更新
        </Button>
      ),
    },
    {
      field: 'delete_button',
      headerName: '削除',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <DeleteButton
          deleteFunc={() => Delete(params.row.id)}
          name={params.row.artist_name}
        />
      ),
    },
  ]

  useEffect(() => {
    fetchInitial()
  }, [])

  const fetchInitial = async () => {
    const { data: tmp } = await getArtists()
    setArtists(tmp)
  }

  const Update = async (artist: Artist) => {
    if (
      artist.artist_name === '' ||
      artist.artist_id === '' ||
      artist.invoice_id === ''
    ) {
      alert('入力されていない項目があります')
      return
    } else {
      try {
        await patchArtist(artist)
        setIsSnackBarOpen(true)
        setIsUnsaved(false)
      } catch (_) {
        setIsAlertOpen(true)
      }
    }
  }

  const Delete = (id: string) => {
    deleteArtist(id)
    setArtists(artists.filter((a) => a.id !== id))
    setIsUnsaved(false)
  }

  const Create = async (artist: {
    artist_id: string
    artist_name: string
    invoice_id: string
    shop_name: string
  }) => {
    try {
      const { data: newArtist } = await createArtist(artist)
      setArtists([...artists, newArtist])
    } catch (_) {
      setIsAlertOpen(true)
    }
  }

  const editCell = (params: GridPreProcessEditCellProps) => {
    if (params.hasChanged) {
      setIsUnsaved(true)
    }
    return params.props
  }

  const editInvoiceIdCell = async (params: GridPreProcessEditCellProps) => {
    if (params.hasChanged) {
      setIsUnsaved(true)
      if (isNaN(Number(params.props.value))) {
        alert('半角数字を入力してください')
        // セルの編集モードを終了する
        params.row.field = 'invoice_id'
        apiRef.current?.stopCellEditMode(params.row)
        // 再度初期値を取得
        const { data: artists } = await getArtists()
        setArtists(artists)
        setIsUnsaved(false)
      }
    }
    return params.props
  }

  useEffect(() => {
    // リロード時に警告を表示する
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }
    // ブラウザの戻るボタンを押した時に警告を表示する

    const handlePopstate = () => {
      const isDiscard = window.confirm(
        '変更が保存されていませんが、よろしいですか？',
      )
      if (isDiscard) {
        navigate('/')
      }
      window.history.pushState(null, '', null)
    }

    if (isUnsaved) {
      window.history.pushState(null, '', null)
      window.addEventListener('beforeunload', handleBeforeUnload)
      window.addEventListener('popstate', handlePopstate, false)
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
        window.removeEventListener('popstate', handlePopstate, false)
      }
    }
  }, [isUnsaved])

  const renderSnackBar = () => (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        open={isSnackBarOpen}
        onClose={() => setIsSnackBarOpen(false)}
      >
        <Alert
          elevation={6}
          severity="success"
          variant="filled"
          onClose={() => setIsSnackBarOpen(false)}
        >
          変更を反映しました
        </Alert>
      </Snackbar>
    </div>
  )

  const renderAlert = () => (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        open={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
      >
        <Alert
          elevation={6}
          severity="error"
          variant="filled"
          onClose={() => setIsAlertOpen(false)}
        >
          エラーが発生しました
        </Alert>
      </Snackbar>
    </div>
  )

  const renderArtistPage = () => (
    <Fragment>
      <Header putArrow isUnsaved={isUnsaved} title="アーティスト管理画面">
        {errorMessages && (
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setIsError(true)
            }}
          >
            エラーを表示
          </Button>
        )}
      </Header>
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        marginTop={10}
      >
        <Box sx={{ height: 'calc(100vh - 160px)', width: '80%' }}>
          <DataGrid
            disableRowSelectionOnClick
            apiRef={apiRef}
            columns={columns}
            pageSizeOptions={[20]}
            rows={artists}
            sx={{ overflowY: 'scroll' }}
          />
        </Box>
        <Box display="flex" justifyContent="end" sx={{ width: '80%' }}>
          <CreateArtistButton createFunc={Create} />
        </Box>
      </Stack>
    </Fragment>
  )
  return (
    <div>
      {renderArtistPage()}
      {renderSnackBar()}
      {renderAlert()}
    </div>
  )
}

export default ArtistPage
