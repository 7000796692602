import { FC, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

type DeleteProps = {
  deleteFunc: () => void
  name: string
}

const DeleteButton: FC<DeleteProps> = ({ deleteFunc, name }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const deleteRow = async () => {
    deleteFunc()
    setOpen(false)
  }

  return (
    <div>
      <Button color="error" variant="outlined" onClick={handleOpen}>
        削除
      </Button>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            「{name}」を本当に削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            やめる
          </Button>
          <Button color="primary" onClick={() => deleteRow()}>
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeleteButton
