import { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'

import { SubmitFile } from '../../pages'
import { InputFile } from '../../types/models/Invoice'
import CheckExcelProtectedWorker from '../../utils/check-excel-protected.worker'
import WorkerLoader from '../../utils/worker-loader'

type FileInputBoxProps = {
  allInputButtonDisabled: boolean
  fileName: string
  isCsv: boolean
  label: string
  name: string
  setAllInputButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setFiles: React.Dispatch<React.SetStateAction<Map<string, SubmitFile>>>
  setIsPasswordModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setPasswordFile: React.Dispatch<React.SetStateAction<InputFile | undefined>>
}

export const FileInputBox = (props: FileInputBoxProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)

  const onCsvFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (!file) return
    props.setFiles((prev) => {
      const newFiles = new Map(prev)
      newFiles.set(props.fileName, {
        file: file,
        name: props.fileName,
        password: undefined,
      })
      return newFiles
    })
  }
  const onXlsxFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (!file) return
    setIsLoading(true)
    props.setAllInputButtonDisabled(true)

    // excelファイルがパスワード保護されているかどうかをチェック、重たいのでworkerで実行
    const worker = new WorkerLoader(
      CheckExcelProtectedWorker,
      (e: { data: { protected: boolean } }) => {
        setIsLoading(false)
        props.setAllInputButtonDisabled(false)
        if (e.data.protected) {
          openPasswordModal()
        }
      },
    )
    worker.postMessage({
      file: file,
      xlsxPath: process.env.REACT_APP_PUBLIC_XLSX_PATH,
    })

    props.setFiles((prev) => {
      const newFiles = new Map(prev)
      newFiles.set(props.fileName, {
        file: file,
        name: props.fileName,
        password: undefined,
      })
      return newFiles
    })
  }

  const openPasswordModal = () => {
    props.setPasswordFile({
      file_name: props.fileName,
      display_name: props.label,
    })
    props.setIsPasswordModalOpen(true)
  }

  return (
    <Stack
      bgcolor="#696969"
      display="flex"
      flex={1}
      flexDirection="column"
      height="30vh"
      justifyContent="space-between"
      p={1}
      sx={{ borderRadius: '8px' }}
    >
      <Typography
        color="#fff"
        component="div"
        height="10%"
        sx={{ flexGrow: 1 }}
        variant="body1"
      >
        {props.label}
      </Typography>
      <Typography
        color="#fff"
        component="div"
        height="10%"
        sx={{ flexGrow: 1 }}
        variant="body1"
      >
        {props.name}
      </Typography>
      <LoadingButton
        component="label"
        disabled={props.allInputButtonDisabled}
        loading={isLoading}
        style={{ marginTop: 'auto' }}
        sx={{
          backgroundColor: props.allInputButtonDisabled
            ? '#1976D2 !important'
            : undefined,
        }}
        variant="contained"
      >
        選択
        <input
          hidden
          multiple
          accept={props.isCsv ? '.csv' : '.xlsx'}
          type="file"
          onChange={(e) =>
            props.isCsv ? onCsvFileInputChange(e) : onXlsxFileInputChange(e)
          }
          onClick={(e) => {
            ;(e.target as HTMLInputElement).value = ''
          }}
        />
        <input hidden type="password" />
      </LoadingButton>
    </Stack>
  )
}
