import { FC, createContext, useState } from 'react'

import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { ErrorModal } from './components/templates/ErrorModal'
import { useRedirectToLogin } from './hooks/useRedirectToLogin'
import ArtistPage from './pages/artist/index'
import IndexPage from './pages/index'
import InvoiceDetailPage from './pages/invoices/[groupId]/index'
import InvoiceGroupsPage from './pages/invoices/index'
import LoginPage from './pages/login'
import UnitPricePage from './pages/unitprice/index'
import { store } from './stores/store'

interface ErrorModalContextProp {
  errorMessages: string
  setErrorMessages: React.Dispatch<React.SetStateAction<string>>
  setIsError: React.Dispatch<React.SetStateAction<boolean>>
}

export const ErrorModalContext = createContext({} as ErrorModalContextProp)

const Pages = () => {
  useRedirectToLogin()
  return (
    <>
      <Routes>
        <Route element={<IndexPage />} path="/" />
        <Route element={<LoginPage />} path="/login" />
        <Route element={<InvoiceGroupsPage />} path="/invoices/*">
          <Route element={<InvoiceDetailPage />} path=":groupId" />
        </Route>
        <Route element={<ArtistPage />} path="/artist" />
        <Route element={<UnitPricePage />} path="/unitprice" />
      </Routes>
    </>
  )
}

const App: FC = () => {
  const [isError, setIsError] = useState(false)
  const [errorMessages, setErrorMessages] = useState<string>('')

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ErrorModalContext.Provider
          value={{ setIsError, setErrorMessages, errorMessages }}
        >
          <Pages />
        </ErrorModalContext.Provider>
        <ErrorModal
          error={errorMessages}
          open={isError}
          setIsOpen={setIsError}
        />
      </BrowserRouter>
    </Provider>
  )
}

export default App
