import { Fragment, useState } from 'react'

import { Stack } from '@mui/material'

import { SubmitFile } from '../../pages'
import { InputFile } from '../../types/models/Invoice'

import { FileInputBox } from './FileInputBox'

type FileInputFormProps = {
  files: Map<string, SubmitFile>
  inputFiles: InputFile[]
  setFiles: React.Dispatch<React.SetStateAction<Map<string, SubmitFile>>>
  setIsPasswordModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setPasswordFile: React.Dispatch<React.SetStateAction<InputFile | undefined>>
}

export const FileInputForm = (props: FileInputFormProps): JSX.Element => {
  // エクセル読み取り中は全てのファイル入力を無効化する
  const [allInputButtonDisabled, setAllInputButtonDisabled] = useState(false)

  return (
    <form>
      <Stack columnGap={1} direction="row" justifyContent="space-between">
        {props.inputFiles.map((file) => {
          return (
            <Fragment key={file.file_name}>
              <FileInputBox
                allInputButtonDisabled={allInputButtonDisabled}
                fileName={file.file_name}
                isCsv={file.file_name.includes('csv')}
                label={file.display_name}
                name={
                  props.files.get(file.file_name)?.file.name ??
                  'ファイルを選択してください'
                }
                setAllInputButtonDisabled={setAllInputButtonDisabled}
                setFiles={props.setFiles}
                setIsPasswordModalOpen={props.setIsPasswordModalOpen}
                setPasswordFile={props.setPasswordFile}
              />
            </Fragment>
          )
        })}
      </Stack>
    </form>
  )
}
