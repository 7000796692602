import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getUserConfig } from '../apis/userConfig'
import { InputFile, UserConfig } from '../types/models/Invoice'

import { RootState } from './store'

interface UserConfigState {
  config: UserConfig
  configSet: boolean
  loggedIn: boolean
}

const initialState: UserConfigState = {
  loggedIn: false,
  configSet: false,
  config: {
    is_pdf_downloadable: false,
    is_invoice_item_addable: false,
    shipper_name: '',
    input_files: [] as InputFile[],
  },
}

export const fetchUserConfig = createAsyncThunk(
  'userConfig/fetchUserConfig',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    if (state.userConfig.configSet) {
      return thunkAPI.rejectWithValue('Config already set')
    }
    const { data: userData } = await getUserConfig()
    return userData
  },
)

export const userConfigSlice = createSlice({
  name: 'userConfig',
  initialState,
  reducers: {
    initialize: (state) => {
      state.loggedIn = false
      state.configSet = false
      state.config = {
        is_pdf_downloadable: false,
        is_invoice_item_addable: false,
        shipper_name: '',
        input_files: [] as InputFile[],
      }
    },
    setConfig: (
      state,
      action: {
        payload: UserConfig
      },
    ) => {
      state.configSet = true
      state.config = action.payload
    },
    loggedIn: (state) => {
      state.loggedIn = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserConfig.fulfilled, (state, action) => {
      state.configSet = true
      state.config = action.payload
    })
  },
})

export const { initialize, setConfig } = userConfigSlice.actions

export const selectUserConfig = (state: RootState) => state.userConfig

export default userConfigSlice.reducer
