import { ReactElement } from 'react'

import { Box, Button, Modal, Stack, Typography } from '@mui/material'

type ErrorModalProps = {
  error: string
  open: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const ErrorModal = ({
  error,
  open,
  setIsOpen,
}: ErrorModalProps): ReactElement => {
  return (
    <Modal
      aria-describedby="modal-description"
      aria-labelledby="modal-title"
      open={open}
      onClose={() => {
        setIsOpen(false)
      }}
    >
      <Stack
        sx={{
          backgroundColor: 'white',
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '80%',
          display: 'flex',
          borderRadius: '10px',
        }}
      >
        {/* Top */}
        <Typography
          fontSize={20}
          fontWeight={600}
          id="modal-title"
          px={3}
          py={1.5}
        >
          エラーが発生しました
        </Typography>

        {/* Contents */}
        <Stack
          id="modal-description"
          spacing={2}
          sx={{
            overflow: 'auto',
            px: 3,
            py: 2,
            flexGrow: 1,
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            background:
              'linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, linear-gradient(to bottom, rgba(0, 0, 0, .1), rgba(0, 0, 0, .0)), linear-gradient(to top, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)) 0 100%',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
            backgroundAttachment: 'local, local, scroll, scroll',
          }}
        >
          {error.split('\n\n').map((line, index) => (
            <Box key={index} sx={{ wordWrap: 'break-word' }} width="100%">
              {line.split('\n').map((text, idx) => {
                return <Box key={idx}>{text}</Box>
              })}
            </Box>
          ))}
        </Stack>

        {/* Bottom */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            py: 1.5,
            px: 3,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setIsOpen(false)
            }}
          >
            閉じる
          </Button>
        </Box>
      </Stack>
    </Modal>
  )
}
