import { FC, Fragment, useContext, useEffect, useState } from 'react'

import { Alert, Box, Button, Snackbar, Stack } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridPreProcessEditCellProps,
} from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'

import { getUnitPrices, patchUnitPrice } from '../../apis/unitPrice'
import { ErrorModalContext } from '../../App'
import { Header } from '../../components/templates/Header'
import { useAppSelector, useAppDispatch } from '../../stores/hooks'
import { fetchUserConfig } from '../../stores/userConfigSlice'
import { MasterUnitPrice } from '../../types/models/Invoice'

const UnitPricePage: FC = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!userConfig.configSet) {
      dispatch(fetchUserConfig())
    }
  }, [])
  const userConfig = useAppSelector((state) => state.userConfig)
  /* const isInvoiceItemAddable = useAppSelector(
    (state) => state.userConfig.config.is_invoice_item_addable,
  ) */

  const { errorMessages, setIsError } = useContext(ErrorModalContext)
  const [unitPrices, setUnitPrices] = useState<MasterUnitPrice[]>([])
  const [initialUnitPrices, setInitialUnitPrices] = useState<MasterUnitPrice[]>(
    [],
  )
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const [isUnsaved, setIsUnsaved] = useState(false)
  const navigate = useNavigate()

  /* const createUnit = async (masterUnit: {
    displayName: string
    unit: string
    unitPrice: number
  }) => {
    try {
      const { data: newMasterUnit } = await crateUnitPrice({
        unit_price: masterUnit.unitPrice,
        display_name: masterUnit.displayName,
        unit: masterUnit.unit,
      })
      setUnitPrices([...unitPrices, newMasterUnit])
      return
    } catch (error) {
      setIsAlertOpen(true)
      return
    }
  } */

  /* const deleteUnit = async (id: string) => {
    try {
      await deleteUnitPrice(id)
      const newUnitPrices = unitPrices.filter((unitPrice) => {
        return unitPrice.id !== id
      })
      setUnitPrices(newUnitPrices)
      return
    } catch (error) {
      setIsAlertOpen(true)
      return
    }
  } */

  const columns: GridColDef[] = [
    /* ...(isInvoiceItemAddable
      ? [
          {
            field: 'fixed',
            headerName: '固定',
            sortable: false,
            headerAlign: 'center' as GridAlignment,
            width: 80,
            disableColumnMenu: true,
            align: 'center' as GridAlignment,
            renderCell: (params: { row: { additional_flag: boolean } }) =>
              !params.row.additional_flag ? (
                <CheckIcon color="primary" />
              ) : (
                <div />
              ),
          },
        ]
      : []), */

    {
      field: 'display_name',
      headerName: '請求項目名',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      /* editable: isInvoiceItemAddable, */
    },
    {
      field: 'unit',
      headerName: '単位',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      /* editable: isInvoiceItemAddable, */
    },
    {
      field: 'unit_price',
      headerName: '単価',
      type: 'text',
      editable: true,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: (params) => editCell(params),
    },
    {
      field: 'edit_button',
      headerName: '更新',
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 90,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => Update(params.row as MasterUnitPrice)}
        >
          更新
        </Button>
      ),
    },
    /* ...(isInvoiceItemAddable
      ? [
          {
            field: 'delete_button',
            headerName: '削除',
            disableColumnMenu: true,
            sortable: false,
            headerAlign: 'center' as GridAlignment,
            align: 'center' as GridAlignment,
            width: 90,
            renderCell: (params: {
              row: {
                additional_flag: boolean
                display_name: string
                id: string
              }
            }) =>
              params.row.additional_flag ? (
                <DeleteButton
                  deleteFunc={() => {
                    deleteUnit(params.row.id)
                  }}
                  name={params.row.display_name}
                />
              ) : (
                <div />
              ),
          },
        ]
      : []), */
  ]

  useEffect(() => {
    fetchInitial()
  }, [])

  const fetchInitial = async () => {
    const { data: tmp } = await getUnitPrices()
    setUnitPrices(tmp)
    setInitialUnitPrices(tmp)
  }

  const Update = async (unitPrice: MasterUnitPrice) => {
    if (isNaN(Number(unitPrice.unit_price))) {
      alert('半角数字を入力してください')
      // 再度初期値を取得
      const { data: tmp } = await getUnitPrices()
      setUnitPrices(tmp)
      return
    }

    // 固定の項目名の変更が不可
    const initial = initialUnitPrices.find((value) => {
      return value.id === unitPrice.id
    })
    if (initial && !initial.additional_flag) {
      if (
        initial.display_name !== unitPrice.display_name ||
        initial.unit !== unitPrice.unit
      ) {
        alert('固定の項目は請求項目名・単位を変更できません。')
        // 再度初期値を取得
        try {
          const { data: tmp } = await getUnitPrices()
          setUnitPrices(tmp)
          return
        } catch (error) {
          setIsAlertOpen(true)
          return
        }
      }
    }

    patchUnitPrice(unitPrice)
    setIsSnackBarOpen(true)
    setIsUnsaved(false)
  }

  const editCell = (params: GridPreProcessEditCellProps) => {
    if (params.hasChanged) {
      setIsUnsaved(true)
    }
    return params.props
  }

  useEffect(() => {
    // リロード時に警告を表示する
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }
    // ブラウザの戻るボタンを押した時に警告を表示する

    const handlePopstate = () => {
      const isDiscard = window.confirm(
        '変更が保存されていませんが、よろしいですか？',
      )
      if (isDiscard) {
        navigate('/')
      }
      window.history.pushState(null, '', null)
    }

    if (isUnsaved) {
      window.history.pushState(null, '', null)
      window.addEventListener('beforeunload', handleBeforeUnload)
      window.addEventListener('popstate', handlePopstate, false)
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
        window.removeEventListener('popstate', handlePopstate, false)
      }
    }
  }, [isUnsaved])

  const renderAlert = () => (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        open={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
      >
        <Alert
          elevation={6}
          severity="error"
          variant="filled"
          onClose={() => setIsAlertOpen(false)}
        >
          エラーが発生しました
        </Alert>
      </Snackbar>
    </div>
  )
  const renderSnackBar = () => (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        open={isSnackBarOpen}
        onClose={() => setIsSnackBarOpen(false)}
      >
        <Alert
          elevation={6}
          severity="success"
          variant="filled"
          onClose={() => setIsSnackBarOpen(false)}
        >
          変更を反映しました
        </Alert>
      </Snackbar>
    </div>
  )

  const renderUnitPage = () => (
    <Fragment>
      <Header putArrow isUnsaved={isUnsaved} title="単価管理画面">
        {errorMessages && (
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setIsError(true)
            }}
          >
            エラーを表示
          </Button>
        )}
      </Header>
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        marginTop={10}
      >
        <Box
          sx={{
            height: 'calc(100vh - 100px)',
            width: '80%',
          }}
        >
          <DataGrid
            disableRowSelectionOnClick
            columns={columns}
            pageSizeOptions={[20]}
            rows={unitPrices}
            sx={{ overflowY: 'scroll' }}
          />
        </Box>
        {/* {isInvoiceItemAddable && (
          <Box display="flex" justifyContent="flex-end" sx={{ width: '80%' }}>
            <CreateMasterUnitButton createFunc={createUnit} />
          </Box>
        )} */}
      </Stack>
    </Fragment>
  )

  return (
    <div>
      {renderUnitPage()}
      {renderSnackBar()}
      {renderAlert()}
    </div>
  )
}

export default UnitPricePage
