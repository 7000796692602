import { useEffect, useState, ReactElement } from 'react'

import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

type Props = {
  decimalPossible?: boolean
  label: string
  maxWidth: number
  negativePossible?: boolean
  updateValue: (value: number) => void
  value: number
}
const StyledInputBox = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '32px',
    borderRadius: '0',
  },
  width: '100%',
})

export const useEditableInputBox = ({
  decimalPossible = false,
  label,
  maxWidth,
  negativePossible = false,
  updateValue,
  value,
}: Props): {
  editableInputBox: ReactElement
  val: number
} => {
  const [val, setVal] = useState(value)
  const [valForShow, setValForShow] = useState(value.toString())
  useEffect(() => {
    setVal(value)
    setValForShow(value.toString())
  }, [value])
  const onChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    let matchString = /^\d+$/u
    if (decimalPossible) {
      matchString = /^\d+(\.\d*)?$/u
    }
    if (negativePossible) {
      matchString = /^-?\d*$/u
    }
    if (negativePossible && decimalPossible) {
      matchString = /^-?\d*(\d*\.\d*)?$/u
    }
    if (inputValue !== null && !inputValue.match(matchString)) {
      console.log('invalida')
      return
    }
    setValForShow(inputValue)
  }

  const onBlur = () => {
    console.log('onBlur')
    if (valForShow === '') {
      setValForShow('0')
      setVal(0)

      if (val !== 0) {
        updateValue(0)
      }
    }

    let matchString = /^\d+$/u
    if (decimalPossible) {
      matchString = /^\d+(\.\d*)?$/u
    }
    if (negativePossible) {
      matchString = /^-?\d+$/u
    }
    if (negativePossible && decimalPossible) {
      matchString = /^-?\d+(\.\d*)?$/u
    }
    if (valForShow !== null && !valForShow.match(matchString)) {
      setValForShow('0')
      setVal(0)
      if (val !== 0) {
        updateValue(0)
      }
      return
    }

    setValForShow(Number(valForShow).toString())
    setVal(Number(valForShow))
    if (val !== Number(valForShow)) {
      updateValue(Number(valForShow))
    }
  }

  const editableInputBox = (
    <StyledInputBox
      label={label}
      style={{ maxWidth }}
      type="text"
      value={valForShow}
      onBlur={onBlur}
      onChange={onChanged}
    />
  )
  return { editableInputBox, val }
}
