import { FC, Fragment, useState } from 'react'

import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Box,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'

type Props = {
  createFunc: (invoiceItem: {
    quantity: number
    unit: string
    unit_name: string
    unit_price: number
  }) => void
}

const CreateInvoiceItemButton: FC<Props> = ({ createFunc }) => {
  const [open, setOpen] = useState(false)
  const [unitName, setUnitName] = useState<string>('')
  const [unitPrice, setUnitPrice] = useState<number>(0)
  const [unit, setUnit] = useState<string>('')
  const [quantity, setQuantity] = useState<number>(0)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setUnitName('')
    setUnitPrice(0)
    setUnit('')
    setQuantity(0)
  }

  const createRow = async () => {
    if (unitName === '' || unit === '') {
      alert('入力されていない項目があります')
      return
    }
    const newInvoiceItem = {
      unit_name: unitName,
      unit_price: unitPrice,
      unit: unit,
      quantity: quantity,
    }
    createFunc(newInvoiceItem)
    setOpen(false)
    setUnitName('')
    setUnitPrice(0)
    setUnit('')
    setQuantity(0)
  }

  return (
    <Fragment>
      <Button
        component="label"
        size="small"
        variant="contained"
        onClick={handleOpen}
      >
        項目を追加
      </Button>
      <Dialog
        fullWidth
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">新規単価マスタ作成</DialogTitle>
        <DialogContent>
          <Stack
            alignItems="center"
            direction="column"
            gap={2}
            justifyContent="center"
          >
            {/* 左詰めで題名 */}
            <Box width="100%">
              <Typography color="gray" sx={{ textAlign: 'left' }}>
                以下の項目を入力してください
              </Typography>
            </Box>

            <TextField
              fullWidth
              required
              autoComplete="off"
              id="MasterUnitName"
              label="単価名"
              margin="normal"
              name="単価名"
              sx={{ margin: 0 }}
              value={unitName}
              variant="outlined"
              onChange={(e) => setUnitName(e.target.value)}
            />
            <TextField
              fullWidth
              required
              autoComplete="off"
              id="MasterUnit"
              label="単位"
              margin="normal"
              name="単位"
              sx={{ margin: 0 }}
              value={unit}
              variant="outlined"
              onChange={(e) => setUnit(e.target.value)}
            />
            <TextField
              fullWidth
              required
              autoComplete="off"
              id="MasterUnitPrice"
              label="単価"
              margin="normal"
              name="単価"
              sx={{ margin: 0 }}
              type="number"
              value={unitPrice}
              variant="outlined"
              onChange={(e) => {
                const inputValue = e.target.value
                if (inputValue === '') {
                  setUnitPrice(0)
                  e.target.value = '0'
                  return
                }
                if (
                  inputValue !== null &&
                  !inputValue.match(/^\d+(\.\d*)?$/u)
                ) {
                  // 入力された文字が数字でない場合は何もしない
                  return
                }
                // 入力された文字が数字のみの場合は先頭の0を削除する
                if (inputValue.match(/^\d+$/u)) {
                  setUnitPrice(Number(inputValue))
                  e.target.value = String(Number(inputValue))
                  return
                }
                setUnitPrice(Number(inputValue))
              }}
            />
            <TextField
              fullWidth
              required
              autoComplete="off"
              id="Quantity"
              label="数量"
              margin="normal"
              name="数量"
              sx={{ margin: 0 }}
              type="number"
              value={quantity}
              variant="outlined"
              onChange={(e) => {
                const inputValue = e.target.value
                if (inputValue === '') {
                  setQuantity(0)
                  e.target.value = '0'
                  return
                }
                if (inputValue !== null && !inputValue.match(/^\d+$/u)) {
                  // 入力された文字が非負整数でない場合は何もしない
                  return
                }
                // 入力された文字が非負整数の場合は値を設定する
                setQuantity(Number(inputValue))
                e.target.value = String(Number(inputValue))
              }}
            />
            <Box display="flex" flexDirection="row-reverse" width="100%">
              <Box display="flex" gap={2}>
                <Button color="info" variant="text" onClick={handleClose}>
                  キャンセル
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => createRow()}
                >
                  追加する
                </Button>
              </Box>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default CreateInvoiceItemButton
