import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface PaletteColor {
    sub?: string
    translucent?: string
  }
  interface SimplePaletteColorOptions {
    sub?: string
    translucent?: string
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#D9E5FF',
      dark: '#303f9f',
      light: '#e3f2fd',
      translucent: 'rgba(48, 63, 159, 0.8)',
    },
    secondary: {
      main: '#B9B9B9',
      dark: '#616060',
      light: '#E5E5E5',
      sub: '#525252',
    },
    warning: {
      main: '#D00000',
      light: '#F1CFCF',
    },
  },
})
