import { FC, useState } from 'react'

import LockOpenIcon from '@mui/icons-material/LockOpen'
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Paper,
  Stack,
  TextField,
  Typography,
  Link,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { login } from '../apis/auth'
import { useAppDispatch } from '../stores/hooks'

const LoginPage: FC = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [loginFailed, setLoginFailed] = useState<boolean>(false)

  const submit = async () => {
    try {
      await login(email, password)
      dispatch({
        type: 'userConfig/loggedIn',
      })
      navigate('/')
    } catch (error) {
      setLoginFailed(true)
      console.log(error)
    }
  }
  const onClickManual = () => {
    const manualUrl =
      'https://sprinkle-hawk-750.notion.site/fanplus-a4a7b4b8ad13431aa5111b831023f9ec?pvs=4'
    window.open(manualUrl, '_blank')
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CssBaseline />

      <Container maxWidth="xs">
        <Stack alignItems="center" direction="column" gap={2} paddingBottom={8}>
          <Box display="flex" justifyContent="center">
            <Avatar sx={{ textAlign: 'center' }}>
              <LockOpenIcon />
            </Avatar>
          </Box>
          <Typography component="h1" textAlign="center" variant="h5">
            SIGN IN
          </Typography>
          {loginFailed && (
            <Alert severity="error">
              Eメールもしくはパスワードが間違えています
            </Alert>
          )}
          <Paper
            elevation={1}
            sx={{ padding: '16px', backgroundColor: '#f6f8fa', width: '100%' }}
          >
            <form noValidate>
              <Stack direction="column" gap={2}>
                <Box>
                  <FormLabel>Eメール</FormLabel>
                  <TextField
                    fullWidth
                    required
                    autoComplete="email"
                    id="email"
                    name="email"
                    size="small"
                    sx={{ bgcolor: 'white' }}
                    value={email}
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
                <Box>
                  <FormLabel>パスワード</FormLabel>
                  <TextField
                    fullWidth
                    required
                    autoComplete="current-password"
                    id="password"
                    name="password"
                    size="small"
                    sx={{ bgcolor: 'white' }}
                    type="password"
                    value={password}
                    variant="outlined"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Box>
                <FormControlLabel
                  control={<Checkbox color="primary" value="remember" />}
                  label="ログイン情報を記憶する"
                />
              </Stack>
              <Button
                fullWidth
                color="primary"
                disabled={!email || !password}
                variant="contained"
                onClick={submit}
              >
                SIGN IN{' '}
              </Button>
            </form>
          </Paper>
          <Link color="#696969" component="button" onClick={onClickManual}>
            マニュアルはこちら
          </Link>
        </Stack>
      </Container>
    </div>
  )
}
export default LoginPage
