import { FC, useEffect, useState, useContext } from 'react'

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { getInvoiceGroups } from '../apis/getInvoiced'
import { postCsv } from '../apis/postCsv'
import { ErrorModalContext } from '../App'
import { FileInputForm } from '../components/templates/FileInputForm'
import { Header } from '../components/templates/Header'
import { PasswordModal } from '../components/templates/PasswordModal'
import { useAppSelector, useAppDispatch } from '../stores/hooks'
import { fetchUserConfig } from '../stores/userConfigSlice'
import { theme } from '../styles/theme'
import { Group, InputFile, UserConfig } from '../types/models/Invoice'

export type SubmitFile = {
  file: File
  name: string
  password: string | undefined
}

const IndexPage: FC = () => {
  /**
   * Global
   */
  useEffect(() => {
    if (!userConfig.configSet) {
      dispatch(fetchUserConfig())
    }
  }, [])
  const userConfig = useAppSelector((state) => state.userConfig)
  useEffect(() => {
    setUser(userConfig.config)
  }, [userConfig])
  const dispatch = useAppDispatch()

  /*
  PasswordModal
  */
  const [files, setFiles] = useState<Map<string, SubmitFile>>(new Map())
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
  const [passwordFile, setPasswordFile] = useState<InputFile | undefined>(
    undefined,
  )

  const { errorMessages, setErrorMessages, setIsError } =
    useContext(ErrorModalContext)
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const navigate = useNavigate()

  /**
   * Input Page
   */

  const [user, setUser] = useState<UserConfig | undefined>(undefined)

  const submitCsvs = async () => {
    setIsFetching(true)
    postCsv(Array.from(files.values()))
      .then((res) => {
        setIsFetching(false)
        setErrorMessages('')
        navigate(`/invoices/${res.data.id}`)
      })
      .catch((error) => {
        if (!axios.isAxiosError(error)) {
          setErrorMessages(
            'エラー９９　「原因不明エラー」\n\n原因不明のエラーが発生しました。もう一度同じ操作を行なってください。\nこのエラーが解決しない場合にはOLIENT TECHまでご連絡ください。',
          )
          setIsFetching(false)
          setIsError(true)
        } else if (
          error.response &&
          400 <= error.response.status &&
          error.response.status < 500
        ) {
          if (error.response && error.response.status === 401) {
            // 401番(unauthorized)
            setIsError(false)
            return
          } else {
            // 400系
            setErrorMessages(error.response.data.detail)
            setIsFetching(false)
            setIsError(true)
          }
        } else {
          //サーバーエラー
          setErrorMessages(
            'エラー９９　「原因不明エラー」\n\n原因不明のエラーが発生しました。もう一度同じ操作を行なってください。\nこのエラーが解決しない場合にはOLIENT TECHまでご連絡ください。',
          )
          setIsFetching(false)
          setIsError(true)
        }
      })
  }

  /**
   * 過去発行が存在するか確認
   */
  const [groups, setGroupsSummary] = useState<Group[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        const { data: groupsData } = await getInvoiceGroups()
        setGroupsSummary(groupsData)
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.response &&
          error.response.status === 401
        ) {
          // 401番(unauthorized)
          setIsError(false)
          return
        } else {
          setIsError(true)
          return
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderCsvInput = () => (
    <>
      <Header
        isUnsaved={false}
        putArrow={false}
        title={user?.shipper_name ?? '請求書作成'}
      >
        {errorMessages && (
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setIsError(true)
            }}
          >
            エラーを表示
          </Button>
        )}
      </Header>

      <Box bgcolor="#c0c0c0" marginTop={7} p={2} sx={{ overflowX: 'auto' }}>
        <Stack p={1}>
          <Typography component="div" fontSize={24}>
            {user?.input_files.length}
            つのファイルを選択後，下記の送信ボタンを押してください
          </Typography>
          <Typography component="div" fontSize={18}>
            ＊ファイルを編集した際は，再度選択し直してください
          </Typography>
        </Stack>
        <FileInputForm
          files={files}
          inputFiles={user?.input_files ?? []}
          setFiles={setFiles}
          setIsPasswordModalOpen={setIsPasswordModalOpen}
          setPasswordFile={setPasswordFile}
        />
      </Box>
      <Stack
        alignItems="center"
        justifyContent="center"
        marginBottom={4}
        marginTop={2}
        rowGap={2}
        width="100%"
      >
        <Button
          component="label"
          disabled={files.size !== user?.input_files.length}
          size="large"
          sx={{ width: '30%' }}
          variant="contained"
          onClick={() => {
            submitCsvs()
          }}
        >
          送信
        </Button>
        <Button
          component="label"
          disabled={groups.length === 0}
          size="large"
          sx={{ width: '30%' }}
          variant="contained"
          onClick={() => {
            navigate('/invoices')
          }}
        >
          過去発行分を表示
        </Button>
        <Button
          component="label"
          size="large"
          sx={{ width: '30%' }}
          variant="contained"
          onClick={() => {
            navigate('/artist')
          }}
        >
          アーティスト管理画面へ
        </Button>
        <Button
          component="label"
          size="large"
          sx={{ width: '30%' }}
          variant="contained"
          onClick={() => {
            navigate('/unitprice')
          }}
        >
          単価管理画面へ
        </Button>
      </Stack>
      <PasswordModal
        open={isPasswordModalOpen}
        passModalClose={() => {
          setIsPasswordModalOpen(false)
        }}
        PasswordFile={passwordFile as InputFile}
        setFiles={setFiles}
      />
    </>
  )

  const renderSnackBar = () => (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        open={isSnackBarOpen}
        onClose={() => setIsSnackBarOpen(false)}
      >
        <Alert
          elevation={6}
          severity="success"
          variant="filled"
          onClose={() => setIsSnackBarOpen(false)}
        >
          変更を反映しました
        </Alert>
      </Snackbar>
    </div>
  )

  /**
   * Loading画面
   */
  const renderLoading = () => (
    <Stack
      direction="column"
      height="100vh"
      sx={{ alignItems: 'center', justifyContent: 'center' }}
      width="100%"
    >
      <CircularProgress
        size={200}
        style={{
          color: theme.palette.secondary.dark,
        }}
      />
      <Typography variant="h5">読み込み中です．少々お待ちください．</Typography>
    </Stack>
  )

  return (
    <div style={{ height: '100%' }}>
      {isFetching ? renderLoading() : renderCsvInput()}
      {renderSnackBar()}
    </div>
  )
}
export default IndexPage
