import { FC, useState } from 'react'

import 'react-native-get-random-values'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'

type Props = {
  createFunc: (artist: {
    artist_id: string
    artist_name: string
    invoice_id: string
    shop_name: string
  }) => void
}

const CreateArtistButton: FC<Props> = ({ createFunc }) => {
  const [open, setOpen] = useState(false)
  const [artistName, setArtistName] = useState<string>('')
  const [artistId, setArtistId] = useState<string>('')
  const [invoiceId, setInvoiceId] = useState<string>('')
  const [shopName, setShopName] = useState<string>('')

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setArtistName('')
    setArtistId('')
    setInvoiceId('')
    setShopName('')
    setOpen(false)
  }

  const createRow = async () => {
    if (artistName === '') {
      alert('入力されていない項目があります')
      return
    }
    const newArtist = {
      artist_name: artistName,
      artist_id: artistId,
      invoice_id: invoiceId,
      shop_name: shopName,
    }
    createFunc(newArtist)
    setArtistName('')
    setArtistId('')
    setInvoiceId('')
    setShopName('')
    setOpen(false)
  }

  return (
    <div>
      <Button
        color="error"
        sx={{ marginTop: 2 }}
        variant="outlined"
        onClick={handleOpen}
      >
        <ControlPointIcon sx={{ marginRight: 1 }} />
        アーティストを追加する
      </Button>
      <Dialog
        fullWidth
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">新規アーティスト作成</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            以下の項目を入力してください
          </DialogContentText>
          <Stack
            alignItems="center"
            direction="column"
            gap={2}
            justifyContent="center"
          >
            <TextField
              fullWidth
              required
              autoComplete="off"
              id="ArtistName"
              label="アーティスト名"
              margin="normal"
              name="アーティスト名"
              sx={{ margin: 0 }}
              value={artistName}
              variant="outlined"
              onChange={(e) => setArtistName(e.target.value)}
            />
            <TextField
              fullWidth
              required
              autoComplete="off"
              id="ArtistId"
              label="取引先ID"
              margin="normal"
              name="取引先ID"
              sx={{ margin: 0 }}
              type="text"
              value={artistId}
              variant="outlined"
              onChange={(e) => {
                setArtistId(e.target.value)
              }}
            />
            <TextField
              fullWidth
              autoComplete="off"
              id="invoiceId"
              label="請求書ID(半角数字)"
              margin="normal"
              name="請求書ID"
              sx={{ margin: 0 }}
              type="text"
              value={invoiceId}
              variant="outlined"
              onChange={(e) => {
                const inputData = e.target.value
                if (inputData !== null && inputData.match(/[^0-9]/u)) {
                  // FormのDataに数字以外が含まれている場合は入力を無効化する
                  return
                }
                setInvoiceId(e.target.value)
              }}
            />
            <TextField
              fullWidth
              autoComplete="off"
              id="shopName"
              label="ショップ名"
              margin="normal"
              name="ショップ名"
              sx={{ margin: 0 }}
              value={shopName}
              variant="outlined"
              onChange={(e) => setShopName(e.target.value)}
            />
            <Box display="flex" flexDirection="row-reverse" width="100%">
              <Box display="flex" gap={2}>
                <Button color="info" variant="text" onClick={handleClose}>
                  キャンセル
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => createRow()}
                >
                  追加する
                </Button>
              </Box>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CreateArtistButton
