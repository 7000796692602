import { FC, useEffect, useRef, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Alert, Button, Snackbar, Stack } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'

import { downloadInvoice } from '../../../apis/downloadInvoice'
import { editInvoice, createInvoiceItem } from '../../../apis/editInvoice'
import { getInvoiceDetail } from '../../../apis/getInvoiced'
import { InputBox } from '../../../components/molecules/InputBox'
import CreateInvoiceItemButton from '../../../components/templates/CreateInvoiceItemButton'
import { ExpenseInputBox } from '../../../components/templates/ExpenseInputBox'
import { InvoiceRow } from '../../../components/templates/InvoiceRows'
import { InvoiceDrawerToggleButton } from '../../../hooks/useDrawerToggle'
import { useAppSelector, useAppDispatch } from '../../../stores/hooks'
import { fetchUserConfig } from '../../../stores/userConfigSlice'
import {
  EditItem,
  Invoice,
  EditExpense,
  PutInvoiceRequest,
} from '../../../types/models/Invoice'

const InvoiceDetailPage: FC = () => {
  /**
   * Global
   */
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!userConfig.configSet) {
      dispatch(fetchUserConfig())
    }
  }, [])
  const userConfig = useAppSelector((state) => state.userConfig)
  const isInvoiceItemAddable = useAppSelector(
    (state) => state.userConfig.config.is_invoice_item_addable,
  )
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const navigate = useNavigate()
  const params = useParams<{ groupId: string }>()

  const renderAlert = () => (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        open={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
      >
        <Alert
          elevation={6}
          severity="error"
          variant="filled"
          onClose={() => setIsSnackBarOpen(false)}
        >
          エラーが発生しました
        </Alert>
      </Snackbar>
    </div>
  )
  const renderSnackBar = () => (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        open={isSnackBarOpen}
        onClose={() => setIsSnackBarOpen(false)}
      >
        <Alert
          elevation={6}
          severity="success"
          variant="filled"
          onClose={() => setIsSnackBarOpen(false)}
        >
          変更を反映しました
        </Alert>
      </Snackbar>
    </div>
  )

  /**
   * 請求書一覧
   */
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [selectedInvoice, selectInvoice] = useState<Invoice>()
  const avoidLoopFlag = useRef(false)
  const prevGroupId = useRef<string>()

  /* 請求書グループ選択時 */
  useEffect(() => {
    ;(async () => {
      if (avoidLoopFlag.current) {
        avoidLoopFlag.current = false
        return
      }
      if (isChanged) {
        const isDiscard = window.confirm(
          '変更が保存されていませんが、よろしいですか？',
        )
        if (!isDiscard) {
          avoidLoopFlag.current = true
          navigate(`/invoices/${prevGroupId.current}`)
          return
        }
      }

      /* 初期化 */
      prevGroupId.current = params.groupId
      setInvoices([])
      selectInvoice(undefined)
      setIsChanged(false)
      setChangedItems(new Map())
      setChangedExpenses(new Map())

      if (!params.groupId) return
      try {
        const { data: groupDetailData } = await getInvoiceDetail(params.groupId)
        setInvoices(groupDetailData.invoices)

        if (groupDetailData.invoices.length !== 0) {
          selectInvoice(groupDetailData.invoices[0])
        }
      } catch (error) {
        setIsAlertOpen(true)
        return
      }
    })()
  }, [params.groupId])

  /* 請求書修正時 */
  const [isChanged, setIsChanged] = useState(false)
  const [changedItems, setChangedItems] = useState<Map<string, EditItem>>(
    new Map(),
  )
  const [changedExpenses, setChangedExpenses] = useState<
    Map<string, EditExpense>
  >(new Map())
  const updateItem = (id: string, quantity: number, unit_price: number) => {
    setIsChanged(true)
    /* changedItemsの更新 */
    const newMap = new Map(changedItems)
    newMap.set(id, {
      id: id,
      quantity: quantity,
      unit_price: unit_price,
    })
    setChangedItems(newMap)

    /* amountsの更新 */
    const newAmounts = new Map(amounts)
    newAmounts.set(`item_${id}`, quantity * unit_price)
    setAmounts(newAmounts)
  }
  const updateExpense = (id: string, total_price: number, quantity: number) => {
    setIsChanged(true)
    /* changedItemsの更新 */
    const newMap = new Map(changedExpenses)
    newMap.set(id, {
      id: id,
      total_price: total_price,
      quantity: quantity,
    })
    setChangedExpenses(newMap)

    /* amountsの更新 */
    const newAmounts = new Map(amounts)
    newAmounts.set(`expense_${id}`, total_price)
    setAmounts(newAmounts)
  }

  /* 請求書選択時 */
  const clickInvoice = (invoice: Invoice) => {
    if (selectedInvoice !== invoice) {
      if (isChanged) {
        const isDiscard = window.confirm(
          '変更が保存されていませんが、よろしいですか？',
        )
        if (isDiscard) {
          setChangedItems(new Map())
          setChangedExpenses(new Map())
          setIsChanged(false)
          selectInvoice(invoice)
        }
        return
      }
      selectInvoice(invoice)
    }
    return
  }
  /* 各項目のidと合計額 */
  const [amounts, setAmounts] = useState<Map<string, number>>(new Map())
  useEffect(() => {
    /* 初期化 */
    const newMap = new Map()
    if (selectedInvoice) {
      selectedInvoice.items.forEach((item) => {
        newMap.set(`item_${item.id}`, item.quantity * item.unit_price)
      })
      selectedInvoice.expenses.forEach((item) => {
        newMap.set(`expense_${item.id}`, item.total_price)
      })
    }
    setAmounts(newMap)
  }, [selectedInvoice])

  const [sumAmount, setSumAmount] = useState<number>(0)
  /* 合計金額を計算 */
  useEffect(() => {
    let sum = 0
    amounts.forEach((value) => {
      sum += value
    })
    setSumAmount(sum)
  }, [amounts])

  /* 更新 */
  const editAndFetch = async () => {
    if (!selectedInvoice || !params.groupId) return
    const request = {
      items: [] as EditItem[],
      expenses: [] as EditExpense[],
    } as PutInvoiceRequest
    changedItems.forEach((value) => {
      request.items.push(value)
    })
    changedExpenses.forEach((value) => {
      request.expenses.push(value)
    })
    try {
      await editInvoice(request, params.groupId, selectedInvoice.id)

      /* invoicesの更新 */
      const newInvoices = invoices.map((invoice) => {
        if (invoice.id === selectedInvoice.id) {
          return {
            ...invoice,
            items: invoice.items.map((item) => {
              const newItem = changedItems.get(item.id)
              if (newItem) return { ...item, ...newItem }
              else return item
            }),
            expenses: invoice.expenses.map((expense) => {
              const newExpense = changedExpenses.get(expense.id)
              if (newExpense) return { ...expense, ...newExpense }
              else return expense
            }),
          }
        } else {
          return invoice
        }
      })
      setInvoices(newInvoices)

      setChangedItems(new Map())
      setChangedExpenses(new Map())
      setIsChanged(false)
      setIsSnackBarOpen(true)
    } catch (error) {
      setIsAlertOpen(true)
      return
    }
  }

  useEffect(() => {
    // リロード時に警告を表示する
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }
    // ブラウザの戻るボタンを押した時に警告を表示する
    const handlePopstate = () => {
      const isDiscard = window.confirm(
        '変更が保存されていませんが、よろしいですか？',
      )
      if (isDiscard) {
        // window.history.back() だと何度もクリックしないと戻らないので、navigateを使う
        navigate('/')
      }
      window.history.pushState(null, '', null)
    }

    if (isChanged) {
      window.history.pushState(null, '', null)
      window.addEventListener('beforeunload', handleBeforeUnload)
      window.addEventListener('popstate', handlePopstate, false)
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
        window.removeEventListener('popstate', handlePopstate, false)
      }
    }
  }, [isChanged])

  // 請求書出力
  const [isDownloading, setIsDownloading] = useState(false)
  const onClickDownload = async () => {
    if (isChanged) {
      const isDiscard = window.confirm(
        '変更が保存されていませんが、よろしいですか？',
      )
      if (!isDiscard) {
        return
      }
    }
    if (!params.groupId) return
    try {
      setIsDownloading(true)
      await downloadInvoice(params.groupId)
    } catch (error) {
      setIsDownloading(false)
      setIsAlertOpen(true)
      return
    }
    setIsDownloading(false)
  }

  /* 請求項目追加 */
  const postInvoiceItem = async (newInvoiceItem: {
    quantity: number
    unit: string
    unit_name: string
    unit_price: number
  }) => {
    if (!selectedInvoice || !params.groupId) return
    try {
      const { data: res } = await createInvoiceItem(
        newInvoiceItem,
        params.groupId,
        selectedInvoice.id,
      )

      const newInvoice = {
        ...selectedInvoice,
        items: [
          ...selectedInvoice.items,
          {
            display_name: newInvoiceItem.unit_name,
            id: res.id,
            name: newInvoiceItem.unit_name,
            quantity: newInvoiceItem.quantity,
            unit_price: newInvoiceItem.unit_price,
          },
        ],
      }
      selectInvoice(newInvoice)
    } catch (error) {
      setIsAlertOpen(true)
      return
    }
  }

  const renderInvoices = () => (
    <>
      <InvoiceDrawerToggleButton
        clickInvoice={clickInvoice}
        invoices={invoices}
        selectedInvoice={selectedInvoice}
      />
      <Stack
        direction="column"
        sx={{
          gap: '10px',
          marginLeft: '400px',
          marginRight: '180px',
          padding: '36px',
        }}
      >
        {selectedInvoice &&
          selectedInvoice.items.map((item) => (
            <InvoiceRow key={item.id} item={item} updateItem={updateItem} />
          ))}
        {selectedInvoice &&
          selectedInvoice.expenses.map((item) => (
            <ExpenseInputBox
              key={item.id}
              item={item}
              updateExpense={updateExpense}
            />
          ))}
        <InputBox label="合計" value={sumAmount} />
      </Stack>
      <Stack
        sx={{
          gap: '10px',
          position: 'fixed',
          right: '40px',
          bottom: '40px',
        }}
      >
        {isInvoiceItemAddable && (
          <CreateInvoiceItemButton
            createFunc={(newInvoiceItem) => {
              postInvoiceItem(newInvoiceItem)
            }}
          />
        )}
        <Button
          component="label"
          disabled={!isChanged}
          size="small"
          variant="contained"
          onClick={() => editAndFetch()}
        >
          変更を保存
        </Button>
        <LoadingButton
          component="label"
          disabled={!invoices.length}
          loading={isDownloading}
          size="small"
          sx={{
            backgroundColor: isDownloading ? '#1976D2 !important' : undefined,
          }}
          variant="contained"
          onClick={onClickDownload}
        >
          請求書を出力
        </LoadingButton>
        <Button
          component="label"
          size="small"
          variant="contained"
          onClick={() => {
            if (isChanged) {
              const isDiscard = window.confirm(
                '変更が保存されていませんが、よろしいですか？',
              )
              if (isDiscard) {
                setIsChanged(false)
                navigate('/')
              }
              return
            }
            navigate('/')
          }}
        >
          ホーム画面
        </Button>
      </Stack>
    </>
  )

  return (
    <div>
      {renderInvoices()}
      {renderSnackBar()}
      {renderAlert()}
    </div>
  )
}
export default InvoiceDetailPage
