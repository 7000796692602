/* eslint-disable */
export default class WorkerLoader {
  private worker: Worker

  constructor(
    worker: () => void,
    onMessage?: (this: Worker, ev: MessageEvent) => any,
  ) {
    const code = worker.toString()
    const blob = new Blob([`(${code})()`])
    const workerUrl = URL.createObjectURL(blob)

    this.worker = new Worker(workerUrl)

    if (this.worker) {
      URL.revokeObjectURL(workerUrl)
    }

    if (onMessage) {
      this.worker.onmessage = onMessage
    }
  }

  postMessage(message: any): void {
    this.worker.postMessage(message)
  }

  terminate(): void {
    this.worker.terminate()
  }
}
