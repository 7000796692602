import { useState } from 'react'

import { Modal, Box, Typography, TextField, Button } from '@mui/material'

import { SubmitFile } from '../../pages'
import { InputFile } from '../../types/models/Invoice'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
type PasswordModalProps = {
  PasswordFile: InputFile
  open: boolean
  passModalClose: () => void
  setFiles: React.Dispatch<React.SetStateAction<Map<string, SubmitFile>>>
}

export const PasswordModal = (props: PasswordModalProps): JSX.Element => {
  const [password, setPassword] = useState('')
  const handleClose = (_: Event, reason: string) => {
    if (reason !== 'backdropClick') {
      props.passModalClose()
    }
  }

  return (
    <Modal
      aria-labelledby="password-modal-title"
      open={props.open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Typography component="h2" id="password-modal-title" variant="h6">
          パスワードを入力してください
        </Typography>
        <form
          noValidate
          onSubmit={(e) => {
            props.setFiles((prev) => {
              const newFiles = new Map(prev)
              const file = prev.get(props.PasswordFile.file_name)
              if (!file) return newFiles
              newFiles.set(props.PasswordFile.file_name, {
                file: file.file,
                name: file.name,
                password: password,
              })
              return newFiles
            })
            props.passModalClose()
            e.preventDefault()
          }}
        >
          <TextField
            fullWidth
            required
            autoComplete="off"
            id="password"
            label="Password"
            margin="normal"
            name="password"
            type="password"
            variant="outlined"
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <Button
            fullWidth
            color="primary"
            disabled={password.length === 0}
            type="submit"
            variant="contained"
          >
            入力
          </Button>
        </form>
      </Box>
    </Modal>
  )
}
