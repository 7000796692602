import axios from 'axios'

import { API_HOST } from '../constants/const'

let client
console.log('API_HOST: ', API_HOST)

export default client = axios.create({
  baseURL: `${API_HOST}`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

client.interceptors.response.use((response) => {
  const data = response.data
  return { headers: response.headers, data }
})
