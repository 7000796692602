import { AxiosResponse } from 'axios'

import client from './client'

export const downloadInvoice = (
  group_id: string,
): Promise<void | AxiosResponse<string>> => {
  return client
    .get(`/groups/${group_id}/download`, { responseType: 'blob' })
    .then((response: AxiosResponse<Blob>) => {
      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {
        type: contentType,
      })
      const url = (window.URL || window.webkitURL).createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = decodeURIComponent(
        response.headers['content-disposition'],
      ).split('"')[1]
      a.click()
      a.remove()
    })
}
