import React, { FC } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  AppBar,
  Button,
  Toolbar,
  IconButton,
  Typography,
  Stack,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { logout } from '../../apis/auth'
import { useAppDispatch } from '../../stores/hooks'

type Props = {
  children?: React.ReactNode
  isUnsaved: boolean
  putArrow: boolean
  title: string
}

export const Header: FC<Props> = ({ children, isUnsaved, putArrow, title }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleUnsavedWarning = () => {
    if (isUnsaved) {
      const isDiscard = window.confirm(
        '変更が保存されていませんが、よろしいですか？',
      )
      if (isDiscard) {
        navigate('/')
      }
      return
    }
    navigate('/')
  }

  const renderArrow = () => (
    <IconButton onClick={() => handleUnsavedWarning()}>
      <ArrowBackIcon />
    </IconButton>
  )

  const submit = async () => {
    try {
      await logout()
      dispatch({
        type: 'userConfig/initialize',
      })
      navigate('/login')
    } catch (error) {
      console.log(error)
    }
  }

  const onClickManual = () => {
    const manualUrl =
      'https://sprinkle-hawk-750.notion.site/fanplus-a4a7b4b8ad13431aa5111b831023f9ec?pvs=4'
    window.open(manualUrl, '_blank')
  }

  return (
    <AppBar color="primary" position="fixed">
      <Toolbar>
        {putArrow && renderArrow()}
        <Typography component="div" ml={5} sx={{ flexGrow: 1 }} variant="h6">
          {title}
        </Typography>
        <Stack direction="row" gap="10px">
          {children}
          <Button
            component="label"
            size="large"
            sx={{
              width: '160px',
              backgroundColor: '#696969',
              ':hover': { backgroundColor: '#5A5A5A' },
            }}
            variant="contained"
            onClick={onClickManual}
          >
            マニュアル
          </Button>
          <Button
            component="label"
            size="large"
            sx={{
              width: '160px',
              backgroundColor: '#696969',
              ':hover': { backgroundColor: '#5A5A5A' },
            }}
            variant="contained"
            onClick={submit}
          >
            ログアウト
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
