import { FC } from 'react'

import { Stack } from '@mui/material'

import { useEditableInputBox } from '../../hooks/useEditableInputBox'
import { InvoiceItem } from '../../types/models/Invoice'
import { InputBox } from '../molecules/InputBox'

type Props = {
  item: InvoiceItem
  updateItem: (id: string, quantity: number, unitPrice: number) => void
}

export const InvoiceRow: FC<Props> = ({ item, updateItem }) => {
  const { editableInputBox: editableQuantityInputBox, val: quantity } =
    useEditableInputBox({
      label: '個数',
      value: item.quantity,
      maxWidth: 150,
      updateValue: (value) => {
        /* 個数変更時に行の合計を計算 */
        updateItem(item.id, value, unit_price)
      },
      decimalPossible: true,
      negativePossible: true,
    })
  const { editableInputBox: editableUnitPriceInputBox, val: unit_price } =
    useEditableInputBox({
      label: '単価',
      value: item.unit_price,
      maxWidth: 150,
      updateValue: (value) => {
        /* 単価変更時に行の合計を計算 */
        updateItem(item.id, quantity, value)
      },
      decimalPossible: true,
      negativePossible: true,
    })

  return (
    <Stack direction="row">
      <InputBox value={item.display_name} />
      {editableQuantityInputBox}
      {editableUnitPriceInputBox}
      <InputBox
        label="合計"
        maxWidth={150}
        value={Number(quantity) * Number(unit_price)}
      />
    </Stack>
  )
}
