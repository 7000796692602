/* eslint-disable */
declare const importScripts: (url: string) => void

export default (): void => {
  self.onmessage = async (event: MessageEvent) => {
    const file = event.data.file as File
    const xlsxPath = event.data.xlsxPath as string

    // XLSXライブラリを読み込む
    importScripts(xlsxPath)
    const isProtected = await checkExcelProtected(file)

    postMessage({ protected: isProtected })
  }

  function checkExcelProtected(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const data = event.target?.result

        try {
          // XLSXライブラリを使用
          ;(self as any).XLSX.read(data as string, { type: 'binary' })
          resolve(false)
        } catch (error: any) {
          const errorMessage = error.message
          if (errorMessage && errorMessage.indexOf('password-protected') > -1) {
            resolve(true)
          } else {
            reject(error)
          }
        }
      }

      reader.onerror = (e: ProgressEvent<FileReader>) => {
        reject(e)
      }

      reader.readAsBinaryString(file)
    })
  }
}
