import { FC } from 'react'

import {
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material'
import Box from '@mui/material/Box'

import { theme } from '../../styles/theme'
import { Group, Invoice } from '../../types/models/Invoice'

type InvoiceProps = {
  clickInvoice: (invoice: Invoice) => void
  invoices: Invoice[]
  selectedInvoice: Invoice | undefined
}

type GroupProps = {
  clickGroup: (group: Group) => void
  groups: Group[]
  selectedGroupId: string | undefined
}

const drawerWidth = '200px'

export const InvoiceDrawerPressList: FC<InvoiceProps> = ({
  clickInvoice,
  invoices,
  selectedInvoice,
}) => {
  return (
    <Drawer
      anchor="left"
      PaperProps={{ sx: { ml: drawerWidth } }}
      style={{
        opacity: 0.9,
        zIndex: 101,
      }}
      sx={{
        position: 'relative',
        '& .MuiDrawer-paper': {
          backgroundColor: theme.palette.primary.dark,
        },
        opacity: 0.9,
        zIndex: 100,
      }}
      variant="permanent"
    >
      <Box
        bgcolor="primary.dark"
        color="#fff"
        role="presentation"
        sx={{ width: 200 }}
      >
        <List>
          {invoices.map((invoice) => (
            <InvoiceDrawerItem
              key={invoice.id}
              clickInvoice={clickInvoice}
              invoice={invoice}
              isSelected={
                selectedInvoice !== undefined &&
                invoice.id === selectedInvoice.id
              }
            />
          ))}
        </List>
      </Box>
    </Drawer>
  )
}

export const GroupDrawerPressList: FC<GroupProps> = ({
  clickGroup,
  groups,
  selectedGroupId,
}) => {
  return (
    <Drawer
      anchor="left"
      PaperProps={{ sx: { width: drawerWidth } }}
      sx={{
        position: 'relative',
        '& .MuiDrawer-paper': {
          backgroundColor: theme.palette.primary.dark,
        },
      }}
      variant="permanent"
    >
      <Box
        bgcolor="primary.dark"
        color="#fff"
        role="presentation"
        sx={{ width: 200 }}
      >
        <List>
          {groups.map((group) => (
            <GroupDrawerItem
              key={group.id}
              clickGroup={clickGroup}
              group={group}
              isSelected={group.id === selectedGroupId}
            />
          ))}
        </List>
      </Box>
    </Drawer>
  )
}

type InvoiceDrawerProps = {
  clickInvoice: (invoice: Invoice) => void
  invoice: Invoice
  isSelected: boolean
}

type GroupDrawerProps = {
  clickGroup: (group: Group) => void
  group: Group
  isSelected: boolean
}

const InvoiceDrawerItem: FC<InvoiceDrawerProps> = ({
  clickInvoice,
  invoice,
  isSelected,
}) => {
  return (
    <ListItemButton
      key={invoice.id}
      style={
        isSelected
          ? {
              backgroundColor: '#fff',
              color: theme.palette.primary.dark,
            }
          : { borderTop: '0.5px solid #fff' }
      }
      onClick={() => clickInvoice(invoice)}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={1}
        width="100%"
      >
        <Box>
          <ListItemText
            primary={invoice.artist && invoice.artist.artist_name}
          />
        </Box>
      </Stack>
    </ListItemButton>
  )
}

const GroupDrawerItem: FC<GroupDrawerProps> = ({
  clickGroup,
  group,
  isSelected,
}) => {
  return (
    <ListItemButton
      key={group.id}
      style={
        isSelected
          ? {
              backgroundColor: '#fff',
              color: theme.palette.primary.dark,
            }
          : { borderTop: '0.5px solid #fff' }
      }
      onClick={() => clickGroup(group)}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={1}
        width="100%"
      >
        <Box>
          <ListItemText primary={group.name} />
        </Box>
      </Stack>
    </ListItemButton>
  )
}
