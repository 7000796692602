import { AxiosPromise } from 'axios'
import qs from 'qs'

import client from './client'

type AuthResponse = {
  detail: string
}

export const login = (
  email: string,
  password: string,
): AxiosPromise<AuthResponse> => {
  const params = qs.stringify({
    email,
    password,
  })
  return client.post('api/login/', params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const logout = (): AxiosPromise<void> => client.get('api/logout/')

export const checkAuth = (): AxiosPromise<void> =>
  client.get('check_auth/', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
