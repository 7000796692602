import { FC } from 'react'

import { Stack } from '@mui/material'

import { useEditableInputBox } from '../../hooks/useEditableInputBox'
import { Expense } from '../../types/models/Invoice'
import { InputBox } from '../molecules/InputBox'
type Props = {
  item: Expense
  updateExpense: (id: string, total_price: number, quantity: number) => void
}

export const ExpenseInputBox: FC<Props> = ({ item, updateExpense }) => {
  const { editableInputBox: totalPriceInputBox, val: total_price } =
    useEditableInputBox({
      label: '合計',
      value: item.total_price,
      maxWidth: 300,
      updateValue: (value) => {
        updateExpense(item.id, value, quantity)
      },
      decimalPossible: true,
      negativePossible: true,
    })

  const { editableInputBox: editableQuantityInputBox, val: quantity } =
    useEditableInputBox({
      label: '個数',
      value: item.quantity,
      maxWidth: 150,
      updateValue: (value) => {
        updateExpense(item.id, total_price, value)
      },
      decimalPossible: true,
      negativePossible: true,
    })

  return (
    <Stack direction="row">
      <InputBox value={item.display_name} />
      {editableQuantityInputBox}
      {totalPriceInputBox}
    </Stack>
  )
}
