import { AxiosResponse } from 'axios'

import { UserConfig } from '../types/models/Invoice'

import client from './client'

export const getUserConfig = (): Promise<AxiosResponse<UserConfig>> => {
  return client.get(`user_config/`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
