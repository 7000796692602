import { AxiosPromise } from 'axios'

import { Artist } from './../types/models/Invoice'
import client from './client'

const url = '/artists/'

export const createArtist = (artist: {
  artist_id: string
  artist_name: string
  invoice_id: string
  shop_name: string
}): AxiosPromise<Artist> => {
  return client.post(url, artist, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const getArtists = (): AxiosPromise<Artist[]> => {
  return client.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const patchArtist = (artist: Artist): AxiosPromise<void> => {
  return client.patch(`${url}${artist.id}/`, artist, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const deleteArtist = (id: string): AxiosPromise<void> => {
  return client.delete(`${url}${id}/`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
