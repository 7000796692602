import { AxiosPromise } from 'axios'

import { SubmitFile } from '../pages'
import { GroupWithInvoices } from '../types/models/Invoice'

import client from './client'
export const postCsv = (
  csvs: SubmitFile[],
): AxiosPromise<GroupWithInvoices> => {
  console.log('csvs', csvs)
  const data = new FormData()
  csvs.forEach((csv) => {
    data.append(csv.name, csv.file)
    data.append(csv.name + '_pass', csv.password ?? '')
  })
  return client.post('/create_invoice', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
